<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-between">
              <div class="flex items-center">
                <span style="width: 130px">Tìm kiếm:</span>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Tìm kiếm..."
                />
              </div>
              <b-button
                v-b-toggle.collapse-1
                variant="outline-primary"
                class="ml-1"
                style="width: fit-content"
              >
                <div class="flex items-center gap-1">
                  <feather-icon
                    class="cursor-pointer"
                    icon="FilterIcon"
                    size="16"
                  />
                  <span>Filter</span>
                </div>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div class="flex justify-end">
          <b-button variant="primary" class="mt-2" @click="handleClickBtnAdd">
            <span class="text-nowrap">Thêm mới</span>
          </b-button>
        </div>
      </div>
      <b-collapse id="collapse-1" style="transition: all 0.4s ease-out">
        <b-card class="mb-0">
          <!-- <p class="card-text">Collapse contents Here</p> -->
          <div>
            <b-col cols="12" class="w-full mb-1 flex items-center gap-4">
              <div class="flex-1">
                <span>Nhà sản xuất</span>
                <b-form-select
                  v-model="params.manufacturer"
                  :options="listManufacturers"
                  @change="fetchProduct"
                ></b-form-select>
              </div>
              <div class="flex-1">
                <span>Nguồn gốc</span>
                <b-form-select
                  v-model="params.origin"
                  :options="listOrigins"
                  @change="fetchProduct"
                ></b-form-select>
              </div>
              <div class="flex-1">
                <span>Thương hiệu</span>
                <b-form-select
                  v-model="params.brand"
                  :options="listBrands"
                  @change="fetchProduct"
                ></b-form-select>
              </div>
            </b-col>
            <b-col cols="12" class="mb-1 mb-md-0 flex items-center gap-4">
              <div class="flex-1">
                <span>Dạng bào chế</span>
                <b-form-select
                  v-model="params.dosage_form"
                  :options="listDosageForms"
                  @change="fetchProduct"
                ></b-form-select>
              </div>
              <div class="flex-1">
                <span>Quy cách đóng gói</span>
                <b-form-select
                  v-model="params.specification"
                  :options="listSpecifications"
                  @change="fetchProduct"
                ></b-form-select>
              </div>
              <div class="flex-1">
                <span>Đơn vị</span>
                <b-form-select
                  v-model="params.unit"
                  :options="listUnits"
                  @change="fetchProduct"
                ></b-form-select>
              </div>
            </b-col>
          </div>
        </b-card>
      </b-collapse>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refCategoriesTable"
          class="position-relative"
          :items="listProducts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :busy="isLoading"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Không có dữ liệu hiển thị"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mb-1"></b-spinner>
              <br />
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                class="font-weight-bold d-block text-nowrap"
                @click="handleEditProduct(data.item)"
                >{{ data.item.title }}</b-link
              >
            </b-media>
          </template>

          <!-- Column: createdAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">{{
              formatDate(data.item.created_at)
            }}</span>
          </template>
          <!-- Column: updatedAt -->
          <template #cell(updatedAt)="data">
            <span class="text-capitalize">{{
              formatDate(data.item.updatedAt)
            }}</span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <div class="d-flex justify-content-end">
              <feather-icon
                icon="EditIcon"
                size="22"
                class="text-primary cursor-pointer"
                @click="handleEditProduct(data.item)"
              />
              <!-- <feather-icon
                icon="Trash2Icon"
                size="22"
                class="text-danger ml-2 cursor-pointer"
                @click="deleteRate(data.item.id)"
              />-->
            </div>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row class="flex items-center justify-between">
          <b-col
            cols="12"
            md="4"
            class="d-flex flex-1 align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của
              {{ dataMeta.of }}
              mục
            </span>
          </b-col>
          <!-- Per Page -->
          <div cols="12" sm="8" class="flex items-center justify-end flex-1">
            <div>
              <label>Hiển thị</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[10, 25, 50]"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mục</label>
            </div>
            <!-- Pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 mr-1 ml-3"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import AddNewCategory from './components/add-new-form.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import appUtils from "@/utils/appUtils";
import router from "@/router";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    // AddNewCategory
  },
  name: "ProductList",
  setup() {
    const listProducts = ref([]);
    const listManufacturers = ref([]);
    const listBrands = ref([]);
    const listOrigins = ref([]);
    const listDosageForms = ref([]);
    const listSpecifications = ref([]);
    const listUnits = ref([]);
    const isUpdate = ref(false);
    const isSortDirDesc = ref(false);
    const sortBy = ref("");
    const toast = useToast();
    const showTable = ref(false);
    const refCategoriesTable = ref(null);
    const dataCategory = ref({});
    const isAddNewCategorySideBar = ref(false);
    const isUpdateCategorySidebarActive = ref(false);
    const currentPage = ref(1);
    const isLoading = ref(false);
    const perPage = ref(10);
    const searchQuery = ref("");
    const timeout = ref(null);
    const totalCategories = ref(0);
    const params = ref({
      manufacturer: "",
      brand: "",
      origin: "",
      dosage_form: "",
      specification: "",
      unit: "",
    });
    const tableColumns = [
      {
        key: "name",
        label: "Tên sản phẩm",
        sortable: false,
        thStyle: { width: "30%" },
      },
      {
        key: "registration_number",
        label: "Số đăng ký",
        sortable: false,
        thStyle: { width: "10%" },
      },
      // { key: 'ecom_dosage_form.name', label: 'Dạng bào chế', sortable: false },
      // {
      //   key: 'ecom_specification.name',
      //   label: 'Quy cách đóng gói',
      //   sortable: false
      // },
      { key: "ecom_manufacturer.name", label: "Nhà sản xuất", sortable: false },
      {
        key: "ecom_origin.name",
        label: "Nước sản xuất",
        sortable: false,
        thStyle: { width: "14%" },
      },
      {
        key: "ecom_brand.name",
        label: "Thương hiệu",
        sortable: false,
        thStyle: { width: "15%" },
      },
      // {
      //   key: 'created_at',
      //   label: 'Ngày tạo',
      //   class: 'text-right',
      //   sortable: false
      // },
      {
        key: "action",
        label: "",
        sortable: false,
        class: "text-right",
      },
    ];

    watch([currentPage, perPage, searchQuery], () => {
      showTable.value = true;
      clearTimeout(timeout.value);
      timeout.value = setTimeout(() => {
        fetchProduct();
        showTable.value = false;
      }, 1000);
    });

    // watch(
    //   () => store.state.authenticate.userLogin,
    //   (newValue, oldValue) => {
    //     fetchProduct();
    //   },
    //   { deep: true }
    // );

    const handleEditProduct = (data) => {
      isUpdate.value = true;
      router.push({ name: "product-update", params: { id: data.id } });
      // dataCategory.value = {
      //   name: data.name,
      //   id: data.id,
      //   description: data.description,
      //   order_selected: data.priority
      // }
      // isAddNewCategorySideBar.value = true
      // isUpdate.value = true
    };

    const formatDate = (time) => {
      return moment(time).format("HH:mm:ss DD/MM/YYYY");
    };

    // const deleteRate = id => {
    //   // this.$bvModal
    //   //   .msgBoxConfirm('Bạn có chắc chắn muốn xoá Đơn vị này không?', {
    //   //     title: 'Xoá Đơn vị',
    //   //     size: 'sm',
    //   //     okVariant: 'primary',
    //   //     okTitle: 'Xác nhận',
    //   //     cancelTitle: 'Huỷ',
    //   //     cancelVariant: 'outline-secondary',
    //   //     hideHeaderClose: false,
    //   //     centered: true
    //   //   })
    //   //   .then(value => {
    //   //     if (value) {
    //   //       this.deleteProductCategory(id)
    //   //     }
    //   //   })
    // }

    onMounted(() => {
      fetchProduct();
      fetchManufacturer();
      fetchBrand();
      fetchDosageForm();
      fetchOrigin();
      fetchSpecification();
      fetchUnit();
    });

    const refreshTable = () => {
      showTable.value = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        refetchData();
        showTable.value = false;
      }, 2000);
    };

    const handleClickBtnAdd = () => {
      isUpdate.value = false;
      router.push({ name: "add-product-marketplace" }).catch(() => {});
    };

    const fetchProduct = () => {
      isLoading.value = true;
      store
        .dispatch("configProduct/fetchProduct", {
          keyword: searchQuery.value,
          page_size: perPage.value,
          page_num: currentPage.value,
          supplier_id: store.state.authenticate.userLogin?.supplierInfo?.id,
          sort_by: "created_at",
          order: "desc",
          ecom_brand_id: params.value.brand,
          origin_id: params.value.origin,
          manufacturer_id: params.value.manufacturer,
          dosage_form_id: params.value.dosage_form,
          specification_id: params.value.specification,
          ecom_unit_id: params.value.unit,
        })
        .then((response) => {
          isLoading.value = false;
          listProducts.value = response.data?.data || [];
          totalCategories.value = response.data?.page?.total;
          // callback(listProducts.value)
        })
        .catch((e) => {
          isLoading.value = false;
          console.log(e);
          toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi lấy dữ liệu",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const fetchManufacturer = () => {
      isLoading.value = true;
      store
        .dispatch("configProduct/fetchManufacturer", {
          keyword: searchQuery.value,
          page_size: 100,
          page_num: currentPage.value,
          supplier_id: store.state.authenticate.userLogin?.supplierInfo?.id,
          sort_by: "id",
          order: "asc",
        })
        .then((response) => {
          isLoading.value = false;
          listManufacturers.value = response.data?.data || [];
          listManufacturers.value = listManufacturers.value?.map((elm) => {
            return {
              text: elm.name,
              value: elm.id,
            };
          });
          listManufacturers.value.unshift({
            value: "",
            text: "Chọn nhà sản xuất",
          });
          // totalCategories.value = response.data?.page?.total
          // callback(listManufacturers.value)
        })
        .catch((e) => {
          isLoading.value = false;
          console.log(e);
          toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi lấy dữ liệu",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const fetchBrand = () => {
      isLoading.value = true;
      store
        .dispatch("configProduct/fetchBrand", {
          keyword: searchQuery.value,
          page_size: 100,
          page_num: currentPage.value,
          supplier_id: store.state.authenticate.userLogin?.supplierInfo?.id,
          sort_by: "id",
          order: "asc",
        })
        .then((response) => {
          isLoading.value = false;
          listBrands.value = response.data?.data || [];
          listBrands.value = listBrands.value?.map((elm) => {
            return {
              text: elm.name,
              value: elm.id,
            };
          });
          listBrands.value.unshift({
            value: "",
            text: "Chọn thương hiệu",
          });
          // totalCategories.value = response.data?.page?.total
          // callback(listBrands.value)
        })
        .catch((e) => {
          isLoading.value = false;
          console.log(e);
          toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi lấy dữ liệu",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const fetchOrigin = () => {
      isLoading.value = true;
      store
        .dispatch("configProduct/fetchOrigin", {
          keyword: searchQuery.value,
          page_size: 100,
          page_num: currentPage.value,
          supplier_id: store.state.authenticate.userLogin?.supplierInfo?.id,
          sort_by: "id",
          order: "asc",
        })
        .then((response) => {
          isLoading.value = false;
          listOrigins.value = response.data?.data || [];
          listOrigins.value = listOrigins.value?.map((elm) => {
            return {
              text: elm.name,
              value: elm.id,
            };
          });
          listOrigins.value.unshift({
            value: "",
            text: "Chọn nguồn gốc",
          });
          // totalCategories.value = response.data?.page?.total
          // callback(listOrigins.value)
        })
        .catch((e) => {
          isLoading.value = false;
          console.log(e);
          toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi lấy dữ liệu",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const fetchDosageForm = () => {
      isLoading.value = true;
      store
        .dispatch("configProduct/fetchDosageForm", {
          keyword: searchQuery.value,
          page_size: 100,
          page_num: currentPage.value,
          supplier_id: store.state.authenticate.userLogin?.supplierInfo?.id,
          sort_by: "id",
          order: "asc",
        })
        .then((response) => {
          isLoading.value = false;
          listDosageForms.value = response.data?.data || [];
          listDosageForms.value = listDosageForms.value?.map((elm) => {
            return {
              text: elm.name,
              value: elm.id,
            };
          });
          listDosageForms.value.unshift({
            value: "",
            text: "Chọn dạng bào chế",
          });
          // totalCategories.value = response.data?.page?.total
          // callback(listDosageForms.value)
        })
        .catch((e) => {
          isLoading.value = false;
          console.log(e);
          toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi lấy dữ liệu",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const fetchSpecification = () => {
      isLoading.value = true;
      store
        .dispatch("configProduct/fetchSpecification", {
          keyword: searchQuery.value,
          page_size: 100,
          page_num: currentPage.value,
          supplier_id: store.state.authenticate.userLogin?.supplierInfo?.id,
          sort_by: "id",
          order: "asc",
        })
        .then((response) => {
          isLoading.value = false;
          listSpecifications.value = response.data?.data || [];
          listSpecifications.value = listSpecifications.value?.map((elm) => {
            return {
              text: elm.name,
              value: elm.id,
            };
          });
          listSpecifications.value.unshift({
            value: "",
            text: "Chọn quy cách đóng gói",
          });
          // totalCategories.value = response.data?.page?.total
          // callback(listSpecifications.value)
        })
        .catch((e) => {
          isLoading.value = false;
          console.log(e);
          toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi lấy dữ liệu",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };
    const fetchUnit = () => {
      isLoading.value = true;
      store
        .dispatch("configProduct/fetchUnit", {
          keyword: searchQuery.value,
          page_size: 100,
          page_num: currentPage.value,
          supplier_id: store.state.authenticate.userLogin?.supplierInfo?.id,
          sort_by: "id",
          order: "asc",
        })
        .then((response) => {
          isLoading.value = false;
          listUnits.value = response.data?.data || [];
          listUnits.value = listUnits.value?.map((elm) => {
            return {
              text: elm.name,
              value: elm.id,
            };
          });
          listUnits.value.unshift({
            value: "",
            text: "Chọn đơn vị",
          });
          // totalCategories.value = response.data?.page?.total
          // callback(listUnits.value)
        })
        .catch((e) => {
          isLoading.value = false;
          console.log(e);
          toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi lấy dữ liệu",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refCategoriesTable.value
        ? refCategoriesTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value,
      };
    });

    const refetchData = () => {
      refCategoriesTable.value.refresh();
    };

    return {
      params,
      isSortDirDesc,
      sortBy,
      showTable,
      perPage,
      currentPage,
      refCategoriesTable,
      searchQuery,
      dataCategory,
      isUpdateCategorySidebarActive,
      isAddNewCategorySideBar,
      isLoading,
      tableColumns,
      fetchProduct,
      totalCategories,
      dataMeta,
      refetchData,
      store,
      toast,
      isUpdate,
      ToastificationContent,
      listProducts,
      listManufacturers,
      listBrands,
      listDosageForms,
      listOrigins,
      listSpecifications,
      listUnits,
      refreshTable,
      // deleteRate,
      formatDate,
      handleEditProduct,
      appUtils,
      handleClickBtnAdd,
    };
  },
};
</script>

<style>
</style>